<template>
<div class="flex flex-wrap">
    <div class="w-full px-4">
        <Cardcategorie />
    </div>
</div>
</template>

<script>
import Cardcategorie from "@/components/Cards/Cardcategorie.vue";

export default {
    components: {
        Cardcategorie,
    },
};
</script>
