<template>
<a-space>
    <span class="statut" :style="active === 1 ? 'background : rgb(16, 185, 129)' : 'background : #ef4444' "> {{ active == 1 ? 'Actif' : 'Inactif' }} </span>
    <a-tooltip :title="active === 1 ? 'Désactiver' : 'Activer' ">
        <a-switch :checked="checked3" @click="handleSwitch" type="danger" :style="active === 1 ? 'background : rgb(16, 185, 129)' : 'background : #ef4444' " />
    </a-tooltip>
</a-space>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            checked3: false,
            active: this.item.active_catf
        }
    },
    methods: {
        handleSwitch() {
          this.active === 1 ? this.active++ : this.active--;
            this.checked3 = !this.checked3;
        }
    },
}
</script>

<style>
.statut {
    background: rgb(16, 185, 129);
    color: #FFF !important;
    font-weight: 400 !important;
    border: 0 !important;
    border-radius: 20px !important;
    padding: 0 15px !important;
    display: flex;
    height: 24px;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    align-items: center
}
</style>
