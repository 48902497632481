<template>
<div class="flex flex-wrap">
    <div class="w-full px-4">
        <CardCreateFormasan />
    </div>
</div>
</template>

<script>
import CardCreateFormasan from "@/components/Cards/CardCreateFormasan.vue";

export default {
    components: {
        CardCreateFormasan,
    },
};
</script>
