<template>
  <footer class="block py-4">
    <div class="container px-4 mx-auto">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div
        class="flex flex-wrap items-center justify-center md:justify-between"
      >
        <div class="w-full px-4 md:w-4/12">
          <div
            class="py-1 text-sm font-semibold text-center text-blueGray-500 md:text-left"
          >
            Copyright © {{ date }}
            <a
              href="https://www.creative-tim.com?ref=vn-footer-admin"
              class="py-1 text-sm font-semibold text-blueGray-500 hover:text-blueGray-700"
            >
              FYLE CLINIQUE
            </a>
          </div>
        </div>
        <div class="w-full px-4 md:w-8/12">
        
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
