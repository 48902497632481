<template>
<div>
    <main>
        <section class="relative w-full h-full min-h-screen py-40">
            <div class="absolute top-0 w-full h-full bg-no-repeat bg-blueGray-800 bg-full" :style="`background-image: url('${registerBg2}');`"></div>
            <router-view />
        </section>
    </main>
</div>
</template>

<script>

import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
    data() {
        return {
            registerBg2,
        };
    },
};
</script>
