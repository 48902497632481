<template>
<div class="container h-full px-4 mx-auto">
    <div class="flex items-center content-center justify-center h-full">
        <div class="w-full px-4 lg:w-4/12">
            <div class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
                <div class="px-6 py-6 mb-0 rounded-t">
                    <div class="flex items-center justify-center btn-wrapper">
                        <img alt="..." class="mr-1" :src="Logo" />
                    </div>
                    <hr class="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
                    <div class="mb-3 font-bold text-center text-blueGray-400">
                        <small>Connexion à votre compte</small>
                    </div>
                    <form id="form-login" @submit.prevent="handleLoginFormSubmit">
                        <div class="relative w-full mb-3">
                            <label class="block mb-2 text-xs font-bold uppercase text-blueGray-600" htmlFor="grid-password">
                                Login
                            </label>
                            <input type="text" @blur="handleBlur" v-model="form.email" name="email" class="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring" placeholder="Login" />
                            <small class="requis" v-if="errors.statutEmail"> {{ errors.msgEmail }} </small>
                        </div>

                        <div class="relative w-full mb-3">
                            <label class="block mb-2 text-xs font-bold uppercase text-blueGray-600" htmlFor="grid-password">
                                Mot de passe
                            </label>
                            <input type="password" @blur="handleBlurPassword" v-model="form.password" name="password" class="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring" placeholder="Mot de passe" />
                            <small class="requis" v-if="errors.statutPassword"> {{ errors.msgPassword }} </small>
                        </div>
                        <div class="mt-6 text-center">
                            <button :class="{disabled : loading}" class="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none " type="submit">
                                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                S'AUTHENTIFIER
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="relative flex flex-wrap mt-6">
                <div class="w-1/2">
                    <a href="javascript:void(0)" class="text-blueGray-200">
                        <small>Forgot password?</small>
                    </a>
                </div>
                <div class="w-1/2 text-right">
                    <router-link to="/auth/register" class="text-blueGray-200">
                        <small>Create new account</small>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Logo from '@/assets/img/logox1000px.efac2236.png';
import {
    API_URL,
    LOGIN_URL
} from '../../router/APIRouter';
import axios from 'axios';

export default {
    data() {
        return {
            API_URL,
            Logo,
            loading: false,
            form: {
                email: '',
                password: ''
            },
            errors: {
                statutEmail: false,
                msgEmail: '',
                statutPassword: false,
                msgPassword: ''
            },
            validation: false,
            headers: {
                authorization: "authorization-text",
            },
            showError: false
        };
    },
    methods: {
        // Soumission des données

        handleLoginFormSubmit() {
            this.loading = !this.loading;

            // Validation 
            if (!this.form.email) {
                this.errors.msgEmail = "Le champ email est requis."
                this.errors.statutEmail = true;
                this.validation = true;
            }

            if (!this.form.password) {
                this.errors.msgPassword = "Le champ mot de passe est requis."
                this.errors.statutPassword = true;
                this.validation = true;
            }

            if (this.validation) {
                this.loading = !this.loading;
                return false;
            }

            try {
                let formValues = new FormData();
                formValues.append('login_user', this.form.email);
                formValues.append('password_user', this.form.password);

                axios.post(API_URL + LOGIN_URL, formValues).then((res) => {
                    this.loading = !this.loading;
                    if (res.data.statut == "succes") {
                        this.$store.dispatch('loginAction', {
                            data: res.data
                        });
                        this.$router.push('/admin/dashboard')
                    } else {
                        this.$notification.error({
                            message: res.data.message,
                            placement: 'bottom-right'
                        })
                    }
                });

            } catch (error) {
                alert(error)
            }
        },

        // verification du mail
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        // Blur Email
        handleBlur() {
            if (this.form.email) {
                this.errors.statutEmail = false
            }
        },

        // Blur Password
        handleBlurPassword() {
            if (this.form.password) {
                this.errors.statutPassword = false
            }
        }
    },
    mounted() {
        console.log()
    },
    beforeMount() {
        this.$store.getters.getIsconnect ?
            this.$router.push('/admin/dashboard') :
            this.$router.push('/')
    }
};
</script>

<style>
.bg-blueGray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(66, 111, 4);
}

.disabled {
    pointer-events: none;
    opacity: .3;
    cursor: no-drop;
}

.requis {
    color: red;
}
</style>
