import {createStore} from 'vuex';
// import {createApp} from 'vue';
// import App from "@/App.vue";
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import formsan from './modules/formsan';

// Load Vuex
// Vue.use(Vuex);

// Create store
const store = createStore ({
  modules: {
    auth,
    formsan
  },
  plugins: [createPersistedState()]
});


export default store;