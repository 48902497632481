
const state = {
    user: null,
    accessToken: null,
    isConnect: false
};
const getters = {
    getUserInfo(state) {
        return state.user
    },
    getUserLogin(state) {
        return state.user.USER_LOGIN
    },
    getIsconnect(state) {
        return state.isConnect;
    },
    getToken (state) {
        return state.accessToken;
    }
};

const actions = {
    userInfoAction({ commit }, userInfo) {
        commit('setUserInfo', userInfo);
    },

    loginAction({ commit }, { data }) {
        commit('setUserInfo', data);
    },
    actionLogOut({ commit }, state) {
        commit('mutateLogOut', state);
    }
};
const mutations = {
    initialiseStore(state) {
        // Check if the ID exists
        // console.log('2');
        // console.log(localStorage.getItem('store'))
        if (localStorage.getItem('store')) {
            // Replace the state object with the stored item
            this.replaceState(
                Object.assign(state, JSON.parse(localStorage.getItem('store')))
            );
        }
    },

    setUserInfo(state, userData) {
        state.user = userData.resultat;
        state.accessToken = userData.accessToken;
        state.isConnect = true
    },

    mutateLogOut(state) {
        state.user = null
        state.accessToken = null
        state.isConnect = false
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};