<template>
<div id="app">
    <transition name="fade" mode="out-in">
        <keep-alive>
            <router-view />
        </keep-alive>
    </transition>
</div>
</template>

<script>
export default {
    beforeMount() {
        console.log('1')
        this.$store.commit('initialiseStore');
    }
}
</script>
