<template>
<div class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg" :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="px-4 py-3 mb-0 border-0 rounded-t bg-blueGray-50">
        <div class="flex">
            <div class="relative w-full px-4">
                <div class="flex flex-grow-4">
                    <div class="w-full lg:w-9/12 px-4">
                        <div class="text-lg font-semibold" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                            <form action="">
                                <div class="w-full  px-4">
                                    <div class="relative w-full mb-3">
                                        <small class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Enregistrer catégorie
                                        </small>
                                        <input type="text" name="info_abrev" class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="w-full lg:w-3/12 px-4">
                        <small style="opacity: 0;" class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            Enregistrer catégorie
                        </small>
                        <button class="px-4 py-2 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-md focus:outline-none" type="button">
                            Enregistrer
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <a-spin :spinning="chargement">
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Libelle du categorie
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Statut
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Modifier
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Supprimer
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Créer le :
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in categories" :key="item.id_catf">
                        <th class="flex items-center p-4 px-6 text-xs text-left align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <span class="ml-3 font-bold" :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]" style="white-space : normal; display : inline-block">
                                {{ item.libelle_catf }}
                            </span>
                        </th>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <card-switch :item="item" />
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <a-button-group size="small">
                                <a-button style="background :rgb(36, 36, 122);border :0;color : #FFF">
                                    <edit-Outlined />
                                </a-button>
                                <a-button style="background : #180e590f;cursor: no-drop;">MODIFIER</a-button>
                            </a-button-group>
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <a-button-group size="small">
                                <a-button style="background :rgb(239, 68, 68);border :0;color : #FFF">
                                    <delete-Outlined />
                                </a-button>
                                <a-button style="background : #180e590f;cursor: no-drop;">SUPPRIMER</a-button>
                            </a-button-group>
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <span v-if="item.dateenreg_catf">
                                {{ moment (item.dateenreg_catf).format ('DD/MM/YYYY') }} à {{ moment (item.dateenreg_catf).format ('HH:mm') }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </a-spin>
        <a-modal :visible="visibleEditFormsan" @cancel="handleCancelEditFormsan" :destroyOnClose="true" :footer="null" title="MODIFIER FORMSAN" @ok="handleOk" width="1200px">
            <card-edit-formsan @method="handleEditFormsan" :curentFormsan="current" />
        </a-modal>

        <a-modal :visible="preview" :footer="null" @cancel="handleCancel" class="modalImage">
            <div class="flex justify-center"> <img src="@/assets/img/bootstrap.jpg" alt=""></div>
        </a-modal>
    </div>
</div>
</template>

<script>
import moment from 'moment';

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import axios from 'axios';
import {
    API_URL,
    BLOQUER_COMPTE_URL,
    LISTE_CATEGORIE_PAYS_COMMUNE,
    LISTE_FORMSAN_URL,
    RECHERCHE_FORMSAN_URL
} from '../../router/APIRouter';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons-vue';
import CardEditFormsan from './CardEditFormsan.vue';
import CardSwitch from './CardSwitch.vue';

export default {
    data() {
        return {
            bootstrap,
            angular,
            sketch,
            react,
            vue,
            team1,
            team2,
            team3,
            team4,
            formsan: [],
            categories: [],
            moment,
            preview: false,
            img: null,
            searchValue: '',
            loadSearch: false,
            chargement: false,
            visibleEditFormsan: false,
            current: null,
            checked3: false
        };
    },
    components: {
        EditOutlined,
        DeleteOutlined,
        CardEditFormsan,
        CardSwitch
    },
    props: {

        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },

    methods: {
        listeFormSan() {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
            };

            axios.post(API_URL + LISTE_FORMSAN_URL).then((res) => {
                if (res.data.statut == 'succes') {
                    this.formsan = res.data.resultat
                }
            })
        },
        handlePreview() {
            this.preview = !this.preview
        },
        handleCancel() {
            this.preview = !this.preview
        },
        handleCancelEditFormsan() {
            this.visibleEditFormsan = !this.visibleEditFormsan
        },
        createFormsan() {
            this.$router.push('/admin/create-formsan')
        },

        onSearch(searchValue) {
            this.loadSearch = !this.loadSearch;

            let data = new FormData();
            data.append('partenaire', searchValue);

            axios.post(API_URL + RECHERCHE_FORMSAN_URL, data, {
                headers: {
                    'Authorization': `Bearer ${this.$store.getters.getToken}`,
                }
            }).then((res) => {
                if (res.data.statut == 'succes') {
                    this.loadSearch = !this.loadSearch;
                    this.formsan = res.data.resultat
                    if (res.data.resultat.length == 0) {
                        this.$notification.info({
                            message: "Aucune ligne trouvée pour cette recherche",
                            placement: 'bottom-right'
                        })
                    }
                } else {
                    this.loadSearch = !this.loadSearch;
                    this.$notification.error({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                }
            })
        },
        handleBloquerCompte(record) {
            let data = new FormData();
            this.chargement = !this.chargement;
            let code = parseInt(record.hp_etat) === 1 ? 0 : 1;

            data.append('partenaire_id', record.hp_code);
            data.append('response_action', code);

            axios.post(API_URL + BLOQUER_COMPTE_URL, data, {
                headers: {
                    'Authorization': `Bearer ${this.$store.getters.getToken}`,
                }
            }).then((res) => {
                this.chargement = !this.chargement;
                if (res.data.statut == 'succes') {
                    this.$notification.success({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                    this.listeFormSan()
                } else {

                    this.$notification.error({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                }
            })
        },
        showEditFormsanModal(record) {
            this.current = record;
            this.visibleEditFormsan = true
        },
        handleEditFormsan(event) {
            console.log(event)
        },
        getCategoriePaysCommune() {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
            };

            axios.get(API_URL + LISTE_CATEGORIE_PAYS_COMMUNE).then((res) => {
                if (res.data.statut == 'succes') {
                    this.categories = res.data.categories;
                }
            })
        },
        handleSwitch() {
            this.checked3 = !this.checked3
        }
    },
    mounted() {
        this.getCategoriePaysCommune()
    }
};
</script>

<style>
button svg {
    margin-bottom: 8px;
}

.modalImage .ant-modal-content {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}

.create-formsan {
    height: 40px !important;
    border-radius: 20px !important;
    background: #10b981 !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.text-xs {
    font-weight: bold;
}
</style>
