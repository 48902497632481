// export const API_URL = 'https://apiclinique.fyleclinique.com/api';
export const API_URL = 'https://api.fyleclinique.com/api';

// login route
export const LOGIN_URL= '/user/login-backoffice';

// Liste formSan
export const LISTE_FORMSAN_URL= '/partenaire/liste-des-partenaires';

// Create form san url
export const CREATE_FORMSAN_URL = '/backoffice/creer-partenaire'
export const LISTE_CATEGORIE_PAYS_COMMUNE= '/partenaire/liste-pays-commune-categories';
export const DETAILS_FORMSAN_URL= '/partenaire/detail-de-partenaire';
export const RECUPERER_MONTANT_URL= '/partenaire/parametre-logiciel-formsan';

// Logo Upload 
export const LOGO_UPLOAD_URL = '/backoffice/uploader-logo-partenaire';

// recherche Formsan 
export const RECHERCHE_FORMSAN_URL = '/partenaire/liste-des-partenaires';

// Bloquier compte
export const BLOQUER_COMPTE_URL = '/backoffice/boquer-debloquer-partenaire';

// Upload route
export const UPLOAD = '/upload';


//// INFORMATION DES PARAMETRES FORMSAN

export const INFORMATION_FORMSAN_URL = '/partenaire/parametre-information-formation-sanitaire';
export const PRIX_PRESTATION_URL = '/partenaire/parametre-prix-prestation';
export const MODELE_FACTURE_URL = '/partenaire/parametre-modele-facture-et-recu';
export const AFFICHAGE_FACTURE_URL = '/partenaire/parametre-affichage-facture';
export const INFORMATION_DU_BAS_URL = '/partenaire/parametre-information-bas-facture';
export const INFORMATION_DU_PARTENAIRE_URL = '/partenaire/parametre-information-partenaire';
export const RATTACHEMENT_SMS_URL = '/partenaire/parametre-rattachement-sms';
export const RAPPORT_PERIODIQUE_URL = '/partenaire/parametre-rapport-periodique';
export const RAPPEL_SMS_PATIENT_URL = '/partenaire/parametre-rappel-sms-patient';
export const RAPPEL_SMS_MEDECIN_URL = '/partenaire/parametre-rappel-sms-medecin';

