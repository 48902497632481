<template>
<!-- Navbar -->
<nav class="absolute top-0 left-0 z-10 flex items-center w-full p-4 bg-transparent md:flex-row md:flex-nowrap md:justify-start">
    <div class="flex flex-wrap items-center justify-between w-full px-4 mx-autp md:flex-nowrap md:p-10">
        <!-- Brand -->
        <a class="hidden font-semibold text-white uppercase text-title lg:inline-block" href="javascript:void(0)">
            {{ routeName }}
        </a>
        <!-- Form -->
        <form class="flex-row flex-wrap items-center hidden mr-3 md:flex lg:ml-auto">
            <div class="flex w-full ">
                <div class="ciiXUl">
                    {{userInfo &&  userInfo.USER_NOM }} {{userInfo &&  userInfo.USER_PREN }}
                </div>
            </div>
        </form>
        <!-- User -->
        <ul class="flex-col items-center hidden list-none md:flex-row md:flex">
            <user-dropdown />
        </ul>
    </div>
</nav>
<!-- End Navbar -->
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default {
    components: {
        UserDropdown,
    },
    computed: {
        routeName() {
            return this.$route.name
        },
        userInfo() {
            return this.$store.getters.getUserInfo
        },
    }
};
</script>

<style>
.ciiXUl {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Versus", "Poppins", sans-serif;
    color: #FFF;
    text-align: right;
}

.lgoedk {
    font-weight: bold;
    color: rgb(103, 181, 1);
    text-align: right;
}

.text-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
}
</style>
