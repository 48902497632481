import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";
// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";

import Formsan from "@/views/admin/Formsan.vue";
import CreateFormsan from "@/views/admin/CreateFormsan.vue";
import Categorie from "@/views/admin/Categorie.vue";
import Parametres from "@/views/admin/Parametres.vue";


// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import store from "./store/index";

import Antd, { notification, message } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import Index from "@/views/Index.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHandHoldingMedical, faMoneyBillAlt, faStethoscope, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import nprogress from 'nprogress';
// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        name: 'Tableau de bord'
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/formsan",
        component: Formsan,
        name: 'Formation Sanitaires',
      },
      {
        path: "/admin/create-formsan",
        component: CreateFormsan,
        name: 'Créer une formation sanitaire'
      },
      {
        path: "/admin/categorie-formsan",
        component: Categorie,
        name: "Catégorie  Formation sanitaire"
      },
      {
        path: "/admin/parametres/:formsan_id?",
        component: Parametres,
        name: 'Paramètres'
      },
    ],
  },
  {
    path: "/",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  // {
  //   path: "/",
  //   component: Index,
  // },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    nprogress.start();
  }
  next();
});

router.afterEach(() => {
  nprogress.done()
})

let app = createApp(App);

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  console.log('3')
  // console.log(state)
  localStorage.setItem('store', JSON.stringify(state));
});

library.add(faStethoscope, faTags, faHandHoldingMedical, faMoneyBillAlt);

app.use(router);
app.use(store)
app.use(Antd);

app.component("font-awesome-icon", FontAwesomeIcon);

app.config.globalProperties.$notification = notification;
app.config.globalProperties.$message = message;


app.mount("#app");


