<template>
<div class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg" :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="px-4 py-3 mb-0 border-0 rounded-t">
        <div class="flex flex-wrap items-center">
            <div class="relative flex-1 flex-grow w-full max-w-full px-4">
                <div class="flex flex-grow-4">
                    <div class="w-full px-4 lg:w-3/12">
                        <h3 class="text-lg font-semibold" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                            Liste des Formsan
                        </h3>
                    </div>
                    <div class="w-full px-4 lg:w-6/12 liste">
                        <a-input-search allow-clear v-model="searchValue" placeholder="Entrer le nom ou une partie du nom" enter-button size="large" @search="onSearch" :loading="loadSearch" />
                    </div>
                    <div class="w-full px-4 lg:w-3/12">
                        <div class="flex justify-end">
                            <a-button class="create-formsan" @click="createFormsan"> Creer une formsan </a-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <a-spin :spinning="chargement">
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Formsan
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Responsable
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Etat paiement
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Créer le :
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                            Actions
                        </th>
                        <th class="px-6 py-3 text-xs font-semibold text-left uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap" :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in formsan" :key="item.hp_code">
                        <th class="flex items-center p-4 px-6 text-xs text-left align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <img @click="handlePreview" :src="bootstrap" class="w-12 h-12 bg-white border rounded-full" alt="..." style="cursor : pointer" />
                            <span class="ml-3 font-bold" :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]" style="white-space : normal; display : inline-block">
                                {{ item.hp_nomlong }}
                            </span>
                        </th>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            {{ item.hp_directeur }}
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <i class="mr-2 text-orange-500 fas fa-circle"></i> Solde en cours
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <span v-if="item.created_at">
                                {{ moment (item.created_at).format ('DD/MM/YYYY') }} à {{ moment (item.created_at).format ('HH:mm') }}
                            </span>
                        </td>
                        <td class="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                            <div class="flex items-center">
                                <a-space>
                                    <a-button @click="showEditFormsanModal (item)" shape="circle" type="primary" style="background : rgb(16, 185, 129); border : 0">
                                        <edit-outlined />
                                    </a-button>

                                    <a-button shape="circle" type="primary" style="background :rgb(239, 68, 68); border : 0" @click="gotoParametres (item.hp_code) ">
                                        <setting-outlined />
                                    </a-button>

                                    <a-tooltip :title="item.hp_etat == 1 ? 'Bloquer le compte' : 'Debloquer le compte' ">
                                        <a-button v-if="item.hp_etat === 1" shape="circle" type="primary" style="background : rgb(99, 160, 16); border : 0" @click="handleBloquerCompte (item)">
                                            <unlock-outlined />
                                        </a-button>

                                        <a-button v-if="item.hp_etat !== 1" shape="circle" type="primary" style="background : red; border : 0" @click="handleBloquerCompte (item)">
                                            <unlock-outlined />
                                        </a-button>
                                    </a-tooltip>

                                </a-space>
                            </div>
                        </td>
                        <td>
                            <table-dropdown />
                        </td>
                    </tr>
                </tbody>
            </table>
        </a-spin>
        <a-modal v-if="current !== null" :visible="visibleEditFormsan" @cancel="handleCancelEditFormsan" :destroyOnClose="true" :footer="null" :title="current && current.hp_nomlong" @ok="handleOk" width="1200px">
            <card-edit-formsan @method="handleEditFormsan" :curentFormsan="current"/>
        </a-modal>

        <a-modal :visible="preview" :footer="null" @cancel="handleCancel" class="modalImage">
            <div class="flex justify-center"> <img src="@/assets/img/bootstrap.jpg" alt=""></div>
        </a-modal>
    </div>
</div>
</template>

<script>
import moment from 'moment';

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import axios from 'axios';
import {
    API_URL,
    BLOQUER_COMPTE_URL,
    LISTE_FORMSAN_URL,
    RECHERCHE_FORMSAN_URL
} from '../../router/APIRouter';
import {
    EditOutlined,
    SettingOutlined,
    UnlockOutlined
} from '@ant-design/icons-vue';
import TableDropdown from '../Dropdowns/TableDropdown.vue';
import CardEditFormsan from './CardEditFormsan.vue';
import {
    getListeFormsan
} from '../../api/formsan';

export default {
    data() {
        return {
            bootstrap,
            angular,
            sketch,
            react,
            vue,
            team1,
            team2,
            team3,
            team4,
            formsan: [],
            moment,
            preview: false,
            img: null,
            searchValue: '',
            loadSearch: false,
            chargement: false,
            visibleEditFormsan: false,
            current: null,
            getListeFormsan
        };
    },
    components: {
        EditOutlined,
        SettingOutlined,
        UnlockOutlined,
        TableDropdown,
        CardEditFormsan
    },
    props: {

        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },

    methods: {
        listeFormSan() {
            getListeFormsan(API_URL + LISTE_FORMSAN_URL).then((res) => {
                if (res.data.statut == 'succes') {
                    this.formsan = res.data.resultat
                }
            });
        },
        handlePreview() {
            this.preview = !this.preview
        },
        handleCancel() {
            this.preview = !this.preview
        },
        handleCancelEditFormsan() {
            this.visibleEditFormsan = !this.visibleEditFormsan
        },
        createFormsan() {
            this.$router.push('/admin/create-formsan')
        },

        onSearch(searchValue) {
            this.loadSearch = !this.loadSearch;

            let data = new FormData();
            data.append('partenaire', searchValue);

            axios.post(API_URL + RECHERCHE_FORMSAN_URL, data, {
                headers: {
                    'Authorization': `Bearer ${this.$store.getters.getToken}`,
                }
            }).then((res) => {
                if (res.data.statut == 'succes') {
                    this.loadSearch = !this.loadSearch;
                    this.formsan = res.data.resultat
                    if (res.data.resultat.length == 0) {
                        this.$notification.info({
                            message: "Aucune ligne trouvée pour cette recherche",
                            placement: 'bottom-right'
                        })
                    }
                } else {
                    this.loadSearch = !this.loadSearch;
                    this.$notification.error({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                }
            })
        },
        handleBloquerCompte(record) {
            let data = new FormData();
            this.chargement = !this.chargement;
            let code = parseInt(record.hp_etat) === 1 ? 0 : 1;

            data.append('partenaire_id', record.hp_code);
            data.append('response_action', code);

            axios.post(API_URL + BLOQUER_COMPTE_URL, data, {
                headers: {
                    'Authorization': `Bearer ${this.$store.getters.getToken}`,
                }
            }).then((res) => {
                this.chargement = !this.chargement;
                if (res.data.statut == 'succes') {
                    this.$notification.success({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                    this.listeFormSan()
                } else {

                    this.$notification.error({
                        message: res.data.message,
                        placement: 'bottom-right'
                    })
                }
            })
        },
        showEditFormsanModal(record) {
            this.current = record;
            this.visibleEditFormsan = true
        },
        handleEditFormsan(event) {
            alert (event)
        },
        gotoParametres(formsan_id) {
            this.$router.push('/admin/parametres')
            this.$router.push({
                name: 'Paramètres',
                params: {
                    formsan_id: formsan_id
                }
            })
        }
    },
    mounted() {
        this.listeFormSan()
        // console.log (this.$store.state)
    }
};
</script>

<style>
button svg {
    margin-bottom: 8px;
}

.modalImage .ant-modal-content {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}

.create-formsan {
    height: 40px !important;
    border-radius: 20px !important;
    background: #10b981 !important;
    color: #fff !important;
    font-weight: 600 !important;
}
</style>
