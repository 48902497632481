<template>
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-l rounded-lg bg-blueGray-100 border-0">

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <div>
                <a-steps :current="current">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
                <div class="steps-content">

                    <div v-if="steps[current].key == 0">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            INFORMATION GENERALE
                        </h6>
                        <div class="flex flex-wrap">
                            <!-- Abréviation  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Abréviation
                                    </label>
                                    <input type="text" name="info_abrev" v-model="form.info_abrev" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                            <!-- Nom complet  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Nom complet
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="info_nom" v-model="form.info_nom" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_nom')" @keypress="validate('info_nom')" />
                                    <p class="form-input-hint" v-if="!!errors.info_nom">
                                        {{ errors.info_nom }}
                                    </p>
                                </div>
                            </div>

                            <!-- Boîte postale  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Boîte postale
                                    </label>
                                    <input type="text" name="info_bp" v-model="form.info_bp" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                            <!-- Situation géographique  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Situation géographique
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="info_adress" v-model="form.info_adress" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_adress')" @keypress="validate('info_adress')" />
                                    <p class="form-input-hint" v-if="!!errors.info_adress">
                                        {{ errors.info_adress }}
                                    </p>
                                </div>
                            </div>

                            <!-- Pays  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Pays
                                    </label>
                                    <select :class="{borderred : !!errors.info_nom}" name="info_pays" v-model="form.info_pays" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_pays')" @keypress="validate('info_pays')">
                                        <option value=""></option>
                                        <option :value="item.idpays" v-for="(item, i) in optionsPays" :key="i"> {{ item.pays }} </option>
                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.info_pays">
                                        {{ errors.info_pays }}
                                    </p>

                                </div>
                            </div>

                            <!-- Commune  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Commune
                                    </label>
                                    <select :class="{borderred : !!errors.info_nom}" name="info_commune" v-model="form.info_commune" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_commune')" @keypress="validate('info_commune')">
                                        <option value=""></option>
                                        <option v-for="(item, i) in optionsCommune" :key="i" :value="item.com_id"> {{ item.com_nom }} </option>
                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.info_commune">
                                        {{ errors.info_commune }}
                                    </p>
                                </div>
                            </div>

                            <!-- Téléphone  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Téléphone
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="tel" name="info_phone" v-model="form.info_phone" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_phone')" @keypress="validate('info_phone')" />
                                    <p class="form-input-hint" v-if="!!errors.info_phone">
                                        {{ errors.info_phone }}
                                    </p>
                                </div>
                            </div>

                            <!-- Cellulaire  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Cellulaire
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="tel" name="info_cel" v-model="form.info_cel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_cel')" @keypress="validate('info_cel')" />
                                    <p class="form-input-hint" v-if="!!errors.info_cel">
                                        {{ errors.info_cel }}
                                    </p>
                                </div>
                            </div>

                            <!-- Fax  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Fax
                                    </label>
                                    <input type="text" name="info_fax" v-model="form.info_fax" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                </div>
                            </div>

                            <!-- Email  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Email
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="email" name="info_email" v-model="form.info_email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('info_email')" @keypress="validate('info_email')" />
                                    <p class="form-input-hint" v-if="!!errors.info_email">
                                        {{ errors.info_email }}
                                    </p>
                                </div>
                            </div>

                            <!-- Site Web  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Site Web
                                    </label>
                                    <input type="tel" name="info_web" v-model="form.info_web" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                            <!-- Logo  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Logo
                                    </label>
                                    <input type="file" accept="image/*" name="logo" @change="handleFileUpload" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                        </div>
                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                    </div>

                    <div v-if="steps[current].key == 1">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            CONTACT DU RESPONSABLE
                        </h6>
                        <div class="flex flex-wrap">

                            <!-- Type  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Type
                                    </label>
                                    <select :class="{borderred : !!errors.info_nom}" name="c_type" v-model="form.c_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('c_type')" @keypress="validate('c_type')">
                                        <option value=""></option>
                                        <option value="2">Privé</option>
                                        <option value="1">Public</option>

                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.c_type">
                                        {{ errors.c_type }}
                                    </p>
                                </div>
                            </div>

                            <!-- Catégorie  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Catégorie
                                    </label>

                                    <select :class="{borderred : !!errors.info_nom}" name="c_categorie" v-model="form.c_categorie" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('c_categorie')" @keypress="validate('c_categorie')">
                                        <option value=""></option>
                                        <option v-for="(item, i) in optionsCat" :key="i" :value="item.id_catf"> {{ item.libelle_catf }} </option>
                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.c_categorie">
                                        {{ errors.c_categorie }}
                                    </p>
                                </div>
                            </div>

                            <!-- Directeur/Gérant  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Directeur/Gérant
                                    </label>

                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="c_gerant" v-model="form.c_gerant" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('c_gerant')" @keypress="validate('c_gerant')" />
                                    <p class="form-input-hint" v-if="!!errors.c_gerant">
                                        {{ errors.c_gerant }}
                                    </p>
                                </div>
                            </div>

                            <!-- Téléphone  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Téléphone
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="tel" name="c_phone" v-model="form.c_phone" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('c_phone')" @keypress="validate('c_phone')" />
                                    <p class="form-input-hint" v-if="!!errors.c_phone">
                                        {{ errors.c_phone }}
                                    </p>
                                </div>
                            </div>

                            <!-- Cellulaire  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Cellulaire
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="tel" name="c_cel" v-model="form.c_cel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('c_cel')" @keypress="validate('c_cel')" />
                                    <p class="form-input-hint" v-if="!!errors.c_cel">
                                        {{ errors.c_cel }}
                                    </p>
                                </div>
                            </div>

                            <!-- Fax  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Fax
                                    </label>
                                    <input type="text" name="c_fax" v-model="form.c_fax" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                            <!-- Email  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Email
                                    </label>
                                    <input type="email" name="c_email" v-model="form.c_email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                </div>
                            </div>

                            <!-- Lattitude  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Lattitude
                                    </label>
                                    <input type="text" name="c_lat" v-model="form.c_lat" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                </div>
                            </div>

                            <!-- Longitude  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Longitude
                                    </label>
                                    <input type="text" name="c_long" v-model="form.c_long" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                </div>
                            </div>

                            <div class="w-full lg:w-12/12 px-4">
                                <hr class="mt-6 border-b-1 border-blueGray-300" />

                                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    COMPTE FYLE SMS
                                </h6>

                            </div>
                            <!-- Id sms  -->
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Id sms
                                    </label>
                                    <input type="text" name="c_idsms" v-model="form.c_idsms" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                </div>
                            </div>

                            <!-- Libelle sms  -->
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Libelle sms
                                    </label>
                                    <input type="text" name="c_lib_sms" v-model="form.c_lib_sms" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                        </div>
                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                    </div>

                    <div v-if="steps[current].key == 2">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            PARAMETRES PARTENAIRE
                        </h6>
                        <div class="flex flex-wrap">

                            <!-- Période de facturation  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Période de facturation
                                    </label>
                                    <select :class="{borderred : !!errors.info_nom}" name="p_periode" v-model="form.p_periode" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_periode')" @keypress="validate('p_periode')">
                                        <option value=""></option>
                                        <option value="1">Journalier</option>
                                        <option value="2">Hebdomadaire</option>
                                        <option value="3">Mensuelle</option>
                                        <option value="4">Trimestrielle</option>
                                        <option value="5">Semestrielle</option>
                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.p_periode">
                                        {{ errors.p_periode }}
                                    </p>
                                </div>
                            </div>

                            <!-- Montant périodique  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Montant périodique
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="p_montant" v-model="form.p_montant" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_montant')" @keypress="validate('p_montant')" />
                                    <p class="form-input-hint" v-if="!!errors.p_montant">
                                        {{ errors.p_montant }}
                                    </p>
                                </div>
                            </div>

                            <!-- Date de debut d'utilisation  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Date de debut d'utilisation
                                    </label>
                                    <a-date-picker :value="form.p_date" @change="getDate" size="large" style="width : 100%" :locale="locale" :format="dateFormat" @blur="validate('p_date')" @keypress="validate('p_date')" :class="{borderred : !!errors.info_nom}" />
                                    <p class="form-input-hint" v-if="!!errors.p_date">
                                        {{ errors.p_date }}
                                    </p>
                                </div>
                            </div>

                            <!-- Etat paiement du logiciel  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Etat paiement du logiciel
                                    </label>

                                    <select :class="{borderred : !!errors.info_nom}" name="p_etat" v-model="form.p_etat" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_etat')" @keypress="validate('p_etat')">
                                        <option value=""></option>
                                        <option value="0">En cours de paiement</option>
                                        <option value="1">Payé</option>
                                        <option value="2">Location</option>
                                    </select>
                                    <p class="form-input-hint" v-if="!!errors.p_etat">
                                        {{ errors.p_etat }}
                                    </p>
                                </div>
                            </div>

                            <!-- Cout total du logiciel  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Cout total du logiciel
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="p_cout" v-model="form.p_cout" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_cout')" @keypress="validate('p_cout')" />
                                    <p class="form-input-hint" v-if="!!errors.p_cout">
                                        {{ errors.p_cout }}
                                    </p>
                                </div>
                            </div>

                            <!-- Temps avant blocage  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Temps avant blocage
                                    </label>
                                    <input type="text" name="p_temps" v-model="form.p_temps" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>

                            <!-- API  -->
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        API
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="text" name="p_api" v-model="form.p_api" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_api')" @keypress="validate('p_api')" />
                                    <p class="form-input-hint" v-if="!!errors.p_api">
                                        {{ errors.p_api }}
                                    </p>
                                </div>
                            </div>

                            <div class="w-full lg:w-12/12 px-4">
                                <hr class="mt-6 border-b-1 border-blueGray-300" />

                                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    COMPTE ADMINISTRATEUR
                                </h6>

                            </div>
                            <!-- Login  -->
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Login
                                    </label>
                                    <input readonly :class="{borderred : !!errors.info_nom}" type="text" name="p_login" v-model="form.p_login" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_login')" @keypress="validate('p_login')" />
                                    <p class="form-input-hint" v-if="!!errors.p_login">
                                        {{ errors.p_login }}
                                    </p>
                                </div>
                            </div>

                            <!-- Password  -->
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Ancien mot de passe
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="password" name="p_password" v-model="form.p_password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_password')" @keypress="validate('p_password')" />
                                    <p class="form-input-hint" v-if="!!errors.p_password">
                                        {{ errors.p_password }}
                                    </p>
                                </div>
                            </div>

                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Nouveau mot de passe
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="password" name="p_password" v-model="form.p_password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_password')" @keypress="validate('p_password')" />
                                    <p class="form-input-hint" v-if="!!errors.p_password">
                                        {{ errors.p_password }}
                                    </p>
                                </div>
                            </div>

                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Confirmer mot de passe
                                    </label>
                                    <input :class="{borderred : !!errors.info_nom}" type="password" name="p_password" v-model="form.p_password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" @blur="validate('p_password')" @keypress="validate('p_password')" />
                                    <p class="form-input-hint" v-if="!!errors.p_password">
                                        {{ errors.p_password }}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                    </div>

                </div>
                <div class="steps-action">
                    <a-space>
                        <a-button v-if="current == steps.length - 1" style="background : rgb(103, 179, 1); border : 0" type="primary" html-type="submit" :loading="loading">
                            Modifier les informations
                        </a-button>
                        <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Précédent</a-button>
                        <a-button v-if="current < steps.length - 1" type="primary" @click="next">Suivant</a-button>
                    </a-space>
                </div>
            </div>
        </form>

    </div>
</div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/fr_FR";
import axios from 'axios';
import {
    API_URL,
    CREATE_FORMSAN_URL,
    LISTE_CATEGORIE_PAYS_COMMUNE,
    LOGO_UPLOAD_URL,
    RECUPERER_MONTANT_URL
} from '../../router/APIRouter';

import * as Yup from "yup";
import moment from 'moment';

const telephoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationShema = Yup.object().shape({
    info_nom: Yup.string().required("Le nom complet est obligatoire"),
    info_adress: Yup.string().required("La situation géographique est obligatoire"),
    info_pays: Yup.string().required("Le pays est obligatoire"),
    info_commune: Yup.string().required("La commune est obligatoire"),
    info_phone: Yup.string().matches(telephoneRegExp, 'Téléphone non valide').required("Le téléphone est obligatoire").max(10, 'Le numéro de téléphone doit etre de 10 chiffre').min(10, 'Le numéro de téléphone doit etre de 10 chiffre'),
    info_cel: Yup.string().matches(telephoneRegExp, 'Cellulaire non valide').required("Le cellulaire est obligatatoire").max(10, 'Le numéro de téléphone doit etre de 10 chiffre').min(10, 'Le numéro de téléphone doit etre de 10 chiffre'),
    info_email: Yup.string().required("L'email est obligatatoire").email('Email non valide'),

});

const validationShemaCompte = Yup.object().shape({
    c_type: Yup.string().required("Le type est obligatoire"),
    c_phone: Yup.string().required("Le telephone est obligatoire"),
    c_categorie: Yup.string().required("La catégorie est obligatoire"),
    c_gerant: Yup.string().required("Le Nom du Gérant est obligatoire"),
    c_cel: Yup.string().required("Le céllulaire est obligatoire")
});

const validationShemaParams = Yup.object().shape({
    p_periode: Yup.string().required("La période est obligatoire"),
    p_montant: Yup.string().required("La montant est obligatoire"),
    p_etat: Yup.string().required("L'état du paiement est obligatoire"),
    p_cout: Yup.string().required("La coût est obligatoire"),
    p_login: Yup.string().required("Le login est obligatoire"),
    p_password: Yup.string().required("Le mot de passe est obligatatoire"),
    p_date: Yup.string().required("La date est obligatatoire"),
    p_api: Yup.string().required("L'API est obligatatoire"),
});

export default {
    props: {
        method: {
            type: Function
        },
        curentFormsan: {
            type: Object
        }
    },
    data() {
        return {
            moment,
            locale,
            dateFormat: "DD/MM/YYYY",
            current: 0,
            steps: [{
                key: 0,
                title: 'INFORMATION GENERALE',
                content: 'First-content',
            }, {
                key: 1,
                title: 'CONTACT & COMPTE SMS',
                content: 'Second-content',
            }, {
                key: 2,
                title: 'PARAMETRES',
                content: 'Last-content',
            }],
            form: {
                info_abrev: this.curentFormsan.hp_nomcourt,
                info_nom: this.curentFormsan.hp_nomlong,
                info_bp: this.curentFormsan.hp_bp,
                info_adress: this.curentFormsan.hp_adrgeo,
                info_pays: this.curentFormsan.hp_pays,
                info_commune: this.curentFormsan.hp_commune,
                info_phone: this.curentFormsan.hp_tel,
                info_cel: this.curentFormsan.hp_tel,
                info_fax: this.curentFormsan.hp_fax,
                info_email: this.curentFormsan.hp_email,
                info_web: this.curentFormsan.hp_web,
                c_type: parseInt(this.curentFormsan.hp_type_hopi),
                c_categorie: parseInt(this.curentFormsan.hp_categ_hopi),
                c_gerant: this.curentFormsan.hp_directeur,
                c_phone: this.curentFormsan.hp_tel_dir,
                c_cel: this.curentFormsan.hp_cel_dir,
                c_fax: this.curentFormsan.hp_fax_dir,
                c_email: this.curentFormsan.hp_email_dir,
                c_lat: this.curentFormsan.hp_lat,
                c_long: this.curentFormsan.hp_lng,
                c_idsms: this.curentFormsan.hp_sms1,
                c_lib_sms: this.curentFormsan.hp_sms2,
                p_periode: parseInt(this.curentFormsan.hp_typeparten),
                p_montant: '',
                p_date: '',
                p_etat: this.curentFormsan.hp_etat,
                p_cout: this.curentFormsan.hp_nomcourt,
                p_temps: this.curentFormsan.hp_nomcourt,
                p_login: this.$store.getters.getUserLogin,
                p_password: '',
                p_api: this.curentFormsan.hp_api
            },
            errors: {
                info_abrev: '',
                info_nom: '',
                info_bp: '',
                info_adress: '',
                info_pays: '',
                info_commune: '',
                info_phone: '',
                info_cel: '',
                info_fax: '',
                info_email: '',
                info_web: '',
                c_type: '',
                c_categorie: '',
                c_gerant: '',
                c_phone: '',
                c_cel: '',
                c_fax: '',
                c_email: '',
                c_lat: '',
                c_long: '',
                c_idsms: '',
                c_lib_sms: '',
                p_periode: '',
                p_montant: '',
                p_date: '',
                p_etat: '',
                p_cout: '',
                p_temps: '',
                p_login: '',
                p_password: '',
                p_api: ''
            },
            optionsPays: [],
            optionsCommune: [],
            optionsCat: [],
            file: '',
            loading: false,
            periode: ''
        }
    },
    methods: {
        next() {
            if (this.current == 0) {
                // this.formSubmitafterValidation ()
                validationShema
                    .validate(this.form, {
                        abortEarly: false
                    })
                    .then(() => {
                        this.errors = {};
                        this.current++;
                    })
                    .catch(err => {
                        err.inner.forEach(error => {
                            this.errors = {
                                ...this.errors,
                                [error.path]: error.message
                            };
                        });
                    });
            }

            if (this.current == 1) {
                validationShemaCompte
                    .validate(this.form, {
                        abortEarly: false
                    })
                    .then(() => {
                        this.errors = {};
                        this.current++;
                    })
                    .catch(err => {
                        err.inner.forEach(error => {
                            this.errors = {
                                ...this.errors,
                                [error.path]: error.message
                            };
                        });
                    });
            }

        },
        prev() {
            this.current--;
        },
        getCategoriePaysCommune() {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
            };

            axios.get(API_URL + LISTE_CATEGORIE_PAYS_COMMUNE).then((res) => {
                if (res.data.statut == 'succes') {
                    this.optionsPays = res.data.pays;
                    this.optionsCommune = res.data.communes;
                    this.optionsCat = res.data.categories;

                }
            })
        },
        handleSubmitForm(e) {
            e.preventDefault();

            validationShemaParams
                .validate(this.form, {
                    abortEarly: false
                })
                .then(() => {
                    this.errors = {};
                    this.loading = !this.loading;

                    this.formSubmitafterValidation()
                })
                .catch(err => {
                    err.inner.forEach(error => {
                        this.errors = {
                            ...this.errors,
                            [error.path]: error.message
                        };
                    });
                });
        },

        formSubmitafterValidation() {
            let data = new FormData();

            data.append('abr', this.form.info_abrev)
            data.append('nom_complet', this.form.info_nom)
            data.append('bp', this.form.info_bp)
            data.append('siege', this.form.info_adress)
            data.append('pays', this.form.info_pays)
            data.append('com', this.form.info_commune)
            data.append('tel', this.form.info_phone)
            data.append('cel', this.form.info_cel)
            data.append('fax', this.form.info_fax)
            data.append('email', this.form.info_email)
            data.append('web', this.form.info_web)
            data.append('type', this.form.c_type)
            data.append('categ', this.form.c_categorie)
            data.append('dir', this.form.c_gerant)
            data.append('tel_dir', this.form.c_phone)
            data.append('cel_dir', this.form.c_cel)
            data.append('fax_dir', this.form.c_fax)
            data.append('email_dir', this.form.c_email)
            data.append('lat', this.form.c_lat)
            data.append('lng', this.form.c_long)
            data.append('ville', '')
            data.append('sms1', this.form.c_idsms)
            data.append('sms2', this.form.c_lib_sms)
            data.append('api_partenaire', this.form.p_api)
            data.append('PFC_PERIODE_FACTURATION', this.form.p_periode)
            data.append('PFC_MONTANT_PERIODIQUE', this.form.p_montant)
            data.append('PFC_TPS_AVT_BLOCAGE', this.form.p_temps)
            data.append('PFC_DATE_DE_DEBUT_UTILISATION', this.form.p_date)
            data.append('PFC_COUT_TOTAL_LOGICIEL', this.form.p_cout)
            data.append('PFC_ETAT_PAIEMENT_LOGICIEL', this.form.p_etat)
            data.append('login', this.form.p_login)
            data.append('pwd', this.form.p_password);

            if (this.file !== '') {
                let dataImage = new FormData();
                dataImage.append('image', this.file);

                axios.post(API_URL + LOGO_UPLOAD_URL, dataImage, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters.getToken}`,
                    }
                }).then((res) => {
                    if (res.data.status == "succes") {
                        data.append('logo', res.data.resultat);
                        axios.post(API_URL + CREATE_FORMSAN_URL, data, {
                            headers: {
                                'Authorization': `Bearer ${this.$store.getters.getToken}`,
                            }
                        }).then((res) => {
                            if (res.data.statut == "succes") {
                                this.$notification.success({
                                    message: res.data.message,
                                    placement: 'bottom-right'
                                });
                                setTimeout(() => {
                                    this.loading = !this.loading;
                                    this.$router.push('/admin/formsan')
                                }, 3000);
                            } else {
                                this.loading = !this.loading;
                                this.$notification.error({
                                    message: res.data.message,
                                    placement: 'bottom-right'
                                });
                            }
                        });
                    }
                });

            } else {
                data.append('logo', '');
                axios.post(API_URL + CREATE_FORMSAN_URL, data, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters.getToken}`,
                    }
                }).then((res) => {
                    if (res.data.statut == "succes") {
                        this.$notification.success({
                            message: res.data.message,
                            placement: 'bottom-right'
                        });
                        setTimeout(() => {
                            this.loading = !this.loading;
                            this.$router.push('/admin/formsan')
                        }, 3000);
                    } else {
                        this.loading = !this.loading;
                        this.$notification.error({
                            message: res.data.message,
                            placement: 'bottom-right'
                        });
                    }
                });
            }
        },
        validate(field) {

            if (this.current == 0) {
                validationShema
                    .validateAt(field, this.form)
                    .then(() => {
                        this.errors[field] = "";
                    })
                    .catch(err => {
                        this.errors[err.path] = err.message;
                    });
            }

            if (this.current == 1) {
                validationShemaCompte
                    .validateAt(field, this.form)
                    .then(() => {
                        this.errors[field] = "";
                    })
                    .catch(err => {
                        this.errors[err.path] = err.message;
                    });
            }

            if (this.current == 2) {
                validationShemaParams
                    .validateAt(field, this.form)
                    .then(() => {
                        this.errors[field] = "";
                    })
                    .catch(err => {
                        this.errors[err.path] = err.message;
                    });
            }
        },
        getDate(value) {
            this.form.p_date = moment(value).format('DD/MM/YYYY');
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        detailsFormsan() {
            let data = new FormData();
            data.append('parnetaire_id', this.curentFormsan.hp_code);

            axios.post(API_URL + RECUPERER_MONTANT_URL, data, {
                headers: {
                    'Authorization': `Bearer ${this.$store.getters.getToken}`,
                }
            }).then((res) => {
                console.log(res)
                if (res.data.status == "succes") {
                    let data = res.data.resultat;
                    data.map((value, index) => {
                        if (index == 0) {
                            this.periode = value.val_para;
                        } else if (index == 1) {
                            this.form.p_montant = value[0].val_para;
                        } else if (index == 2) {
                            this.form.p_temps = value[0].val_para;
                        } else if (index == 3) {
                            this.form.p_date = moment(value[0].val_para * 1000);
                        } else if (index == 4) {
                            this.form.p_cout = value[0].val_para;
                        } else if (index == 5) {
                            let params = 0;
                            if (value[0].val_para == 'En cours') {
                                params = 0;
                            }
                            this.form.p_etat = params;
                        }
                    })
                } else {
                    this.$notification.error({
                        message: res.data.message,
                        placement: 'bottom-right'
                    });
                }
            });
        }
    },
    beforeMount() {
        this.getCategoriePaysCommune();
        this.detailsFormsan()
    }
}
</script>

<style>
.form-input-hint {
    color: red;
    transition: all ease-in .3s;
}

.borderred {
    border: 1px solid red;
}
</style>
