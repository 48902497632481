<template>
<div>
    <a class="block text-blueGray-500" href="#pablo" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
        <div class="flex items-center">
            <span class="inline-flex items-center justify-center w-12 h-12 text-sm text-white bg-blueGray-200">
                <a-avatar shape="square" size="large">
                    <template #icon>
                        <UserOutlined />
                    </template>
                </a-avatar>
            </span>
        </div>
    </a>
    <div ref="popoverDropdownRef" class="z-50 float-left py-2 text-base text-left list-none bg-white rounded shadow-lg min-w-48" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
        <a href="javascript:void(0);" class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700" @click.prevent="logout">
            Se deconnecter
        </a>
    </div>
</div>
</template>

<script>
import {
    createPopper
} from "@popperjs/core";

import image from "@/assets/img/team-1-800x800.png";
import { UserOutlined } from '@ant-design/icons-vue';

export default {
  components : {
    UserOutlined
  },
    data() {
        return {
            dropdownPopoverShow: false,
            image: image,
        };
    },
    methods: {
        toggleDropdown: function (event) {
            event.preventDefault();
            if (this.dropdownPopoverShow) {
                this.dropdownPopoverShow = false;
            } else {
                this.dropdownPopoverShow = true;
                createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
                    placement: "bottom-start",
                });
            }
        },
        logout() {
            if (this.$store.getters.getIsconnect) {
                this.$store.dispatch('actionLogOut');
                this.$router.push('/');
            }
        }
    },
};
</script>
