<template>
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <div class="flex flex-grow-4">
                    <div class="w-full lg:w-3/12 px-4">
                        <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                            Liste des Formsan
                        </h3>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">
        <section id="section-parametres" style="background: rgb(248, 250, 252); padding : 1.5rem">
            <div class="flex flex-grow-4">
                <div class="w-full lg:w-6/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">VOTRE FORMATION SANITAIRE</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-12/12 px-4">
                                    <div class="relative w-full">
                                        <small> <strong> NOM COPMLET</strong></small>
                                        <input type="text" name="nom" v-model="Formsan.nom" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-8/12 px-4">

                                    <div class="relative w-full">
                                        <small> <strong> ADRESSE GEOGRAPHIQUE</strong></small>
                                        <input type="text" name="adresse" v-model="Formsan.adresse" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong> CONTACTS TEL/CEL/FAX</strong></small>
                                        <input type="text" name="contact" v-model="Formsan.phone" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong> ADRESSE BOITE POSTALE/WEB</strong></small>
                                        <input type="text" name="bp" v-model="Formsan.bp" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong> AFFICHAGE</strong></small>
                                        <select name="info_commune" v-model="Formsan.affichage" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option v-for="(item, i) in affichage" :key="i" :value="item.value"> {{ item.libelle }} </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="flex justify-center items-center flex-col" style="width :100%;height : 100%">
                                        <img src="" alt="" class="mb-3">

                                        <input type="file" accept="image/*" name="logo" @change="handleFileUpload" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />

                                    </div>
                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    MODIFIER LES INFORMATIONS
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">Modèle de Factures / Reçus</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Modèle de Reçu à la Caisse </strong></small>
                                        <select v-model="formModel.caisse" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="TICKET">TICKET</option>
                                            <option value="A5">A5</option>
                                            <option value="3A4">TIERS A4</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Modèle de Reçu à la Caisse de la Pharmacie</strong></small>
                                        <select v-model="formModel.pharmacie" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="TICKET">TICKET</option>
                                            <option value="A5">A5</option>
                                            <option value="3A4">TIERS A4</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Modèle de Facture d'hospitalisation</strong></small>
                                        <select v-model="formModel.hospi" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="A5">A5</option>
                                            <option value="A4">A4</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Modèle de Ticket pour les RDV </strong></small>
                                        <select v-model="formModel.rdv" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="TICKET">TICKET</option>
                                            <option value="3A4">TIERS A4</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Nom du Document </strong></small>
                                        <input v-model="formModel.nom_caisse" type="text" name="nom" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Nom du Document</strong></small>
                                        <input v-model="formModel.nom_pharmacie" type="text" name="adresse" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Nom du Document</strong></small>
                                        <input v-model="formModel.nom_hospi" type="text" name="contact" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Nom du Document</strong></small>
                                        <input v-model="formModel.nom_rdv" type="text" name="bp" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>

                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    MODIFIER LES INFORMATIONS
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>
            <div class="flex flex-row mt-4">
                <div class="w-full lg:w-6/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">Affichage facture</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full">
                                        <small> <strong> Titre de document</strong></small>
                                        <input type="text" name="nom" v-model="formFacture.titre" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong>TVA</strong></small>
                                        <input type="text" name="nom" v-model="formFacture.tva" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong>Durée de validité pour le contrôle</strong></small>
                                        <input type="text" name="nom" v-model="formFacture.dure" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <a-tooltip title="Affichage bloc Code patient sur la facture"><small> <strong> Affichage bloc "Code patient" sur la facture</strong></small></a-tooltip>
                                        <select v-model="formFacture.code_patient" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>

                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full">
                                        <a-tooltip title="Mode de paiement"> <small> <strong> Affichage bloc "Mode de paiement" sur la facture</strong></small></a-tooltip>
                                        <select v-model="formFacture.mode_paiement" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full">
                                        <a-tooltip title="Signature"><small> <strong> Affichage bloc "Signature" sur la facture</strong></small></a-tooltip>
                                        <select v-model="formFacture.signature" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full">
                                        <a-tooltip title="Affichage du Nom de l'Agent à la caisse "><small> <strong> Affichage du Nom de l'Agent à la caisse </strong></small></a-tooltip>
                                        <select v-model="formFacture.nom_agent" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    MODIFIER LES INFORMATIONS
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">Infos sur le bas de la facture</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full">
                                        <small> <strong> Slogan</strong></small>
                                        <input type="text" name="nom" v-model="formInfoBasPage.slogan" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong>Affichage du slogan</strong></small>
                                        <select v-model="formInfoBasPage.affih_slogan" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full">
                                        <small> <strong>Affichage du Slogan</strong></small>
                                        <select v-model="formInfoBasPage.position_slogan" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="L">GAUCHE</option>
                                            <option value="C">CENTRE</option>
                                            <option value="R">DROITE</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full">
                                        <small> <strong> Informations financière</strong></small>
                                        <input v-model="formInfoBasPage.info_finance" type="text" name="bp" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full">
                                        <a-tooltip title="Affichage de l'information financière"><small> <strong> Affichage de l'information financière</strong></small></a-tooltip>
                                        <select v-model="formInfoBasPage.affich_info_finance" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full">
                                        <a-tooltip title="Affichage de l'information financière"><small> <strong> Affichage de l'information financière</strong></small></a-tooltip>
                                        <select v-model="formInfoBasPage.position_info_finance" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="L">GAUCHE</option>
                                            <option value="C">CENTRE</option>
                                            <option value="R">DROITE</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    MODIFIER LES INFORMATIONS
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>

            <div class="flex flex-row mt-4">
                <div class="w-full lg:w-12/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">ACTIVATION DE COMPTE SMS</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full">
                                        <small> <strong> Rattacher mon compte FYLE SMS à mon compte FYLE CLINIQUE ?</strong></small>
                                        <select v-model="formCompteSms.rattacher" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="flex mt-3 px-4">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    ENREGISTRER
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>

            <div class="flex flex-grow-4 mt-4">
                <div class="w-full lg:w-12/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">RAPPORT PERIODIQUE PAR SMS AU GERANT</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> M'envoyer le Bilan journalier des ventes et dépenses par SMS ? </strong></small>
                                        <select v-model="formRapportPeriod.sms_journalier" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> M'envoyer le Bilan Hebdomadaire des ventes et dépenses par SMS ? </strong></small>
                                        <select v-model="formRapportPeriod.sms_hebdo" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> M'envoyer le Bilan Mensuel des ventes et dépenses par SMS ? </strong></small>
                                        <select v-model="formRapportPeriod.sms_mensuel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong>M'envoyer le Bilan Annuel des ventes et dépenses par SMS ? </strong></small>
                                        <select v-model="formRapportPeriod.sms_annuel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Numéro de cellulaire </strong></small>
                                        <input type="text" name="nom" v-model="formRapportPeriod.num_jour" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Numéro de cellulaire</strong></small>
                                        <input type="text" name="adresse" v-model="formRapportPeriod.num_hebdo" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Numéro de cellulaire</strong></small>
                                        <input type="text" name="contact" v-model="formRapportPeriod.num_mensuel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Numéro de cellulaire</strong></small>
                                        <input type="text" name="bp" v-model="formRapportPeriod.num_annuel" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>

                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    ENREGISTRER
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>

            <div class="flex flex-grow-4 mt-4">
                <div class="w-full lg:w-12/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">RAPPEL DE RDV PAR SMS AUX PATIENTS</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un rappel automatique par SMS aux <br /> patients le jour de leur RDV à 6h00 ? </strong></small>
                                        <select v-model="formRappelSmsPatient.unjour" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un rappel automatique par SMS aux <br /> patients à deux (2) jours de leur RDV à 20h30 ? </strong></small>
                                        <select v-model="formRappelSmsPatient.deuxjour" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un rappel automatique par SMS aux <br /> patients à sept (7) jours de leur RDV à 20h30 ? </strong></small>
                                        <select v-model="formRappelSmsPatient.septjour" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    ENREGISTRER
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>

            <div class="flex flex-grow-4 mt-4">
                <div class="w-full lg:w-12/12 px-4">
                    <a-card style="width: 100%">
                        <template #title>
                            <h2 style="font-weight : bold">RAPPEL DE RDV PAR SMS AUX MEDECINS</h2>
                        </template>
                        <form>
                            <div class="flex flex-grow-4">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un recap automatique des RDV <br /> DU JOUR de chaque Médecin par SMS à 6h30 ? </strong></small>
                                        <select v-model="formRappelSmsMedecin.lejour" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un recap automatique des RDV <br /> DU LENDEMAIN de chaque Médecin par SMS, la veille à 20h30 ? </strong></small>
                                        <select v-model="formRappelSmsMedecin.lelendemain" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un recap automatique des RDV <br /> DE LA SEMAINE EN COURS de chaque Médecin par SMS le Lundi à 6h20 ? </strong></small>
                                        <select v-model="formRappelSmsMedecin.lasemaine" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>

                                    <div class="relative w-full mb-3">
                                        <small> <strong> Envoyer un recap automatique des RDV <br /> DE LA SEMAINE PROCHAINE de chaque Médecin par SMS, le dimanche à 20h30 ? </strong></small>
                                        <select v-model="formRappelSmsMedecin.lasemainepro" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="1">OUI</option>
                                            <option value="0">NON</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="flex mt-3 justify-center">
                                <a-button style="background : #10b981; color: #FFF;border : 0" @click="handleFormsanUpdateSubmit">
                                    ENREGISTRER
                                </a-button>
                            </div>
                        </form>
                    </a-card>
                </div>
            </div>

        </section>
    </div>
</div>
</template>

<script>
import {
    listeFetcherData
} from '../../api/formsan';
import {
    AFFICHAGE_FACTURE_URL,
    INFORMATION_DU_BAS_URL,
    INFORMATION_DU_PARTENAIRE_URL,
    MODELE_FACTURE_URL,
    RAPPEL_SMS_MEDECIN_URL,
    RAPPEL_SMS_PATIENT_URL,
    RAPPORT_PERIODIQUE_URL,
    RATTACHEMENT_SMS_URL
} from '../../router/APIRouter';
export default {
    data() {
        return {
            Formsan: {
                nom: 'dfdfdfdffdfdfdf',
                bp: '',
                adresse: '',
                affichage: 'C',
                phone: ''
            },
            file: '',
            formsan_id: '',
            listeFetcherData,
            affichage: [{
                    libelle: 'Gauche',
                    value: 'L'
                },
                {
                    libelle: 'Centre',
                    value: 'C'
                },
                {
                    libelle: 'Droite',
                    value: 'D'
                }
            ],
            formModel: {
                caisse: 'TICKET',
                nom_caisse: '',
                pharmacie: 'TICKET',
                nom_pharmacie: '',
                hospi: 'A5',
                nom_hospi: '',
                rdv: 'TICKET',
                nom_rdv: ''
            },
            formFacture: {
                titre: '',
                dure: '',
                tva: '',
                code_patient: '',
                mode_paiement: '',
                signature: '',
                nom_agent: '',
            },
            formInfoBasPage: {
                slogan: '',
                affih_slogan: '',
                position_slogan: '',
                info_finance: '',
                affich_info_finance: '',
                position_info_finance: ''
            },
            formCompteSms: {
                rattacher: ''
            },
            formRapportPeriod: {
                sms_journalier: '',
                num_jour: '',
                sms_hebdo: '',
                num_hebdo: '',
                sms_mensuel: '',
                num_mensuel: '',
                sms_annuel: '',
                num_annuel: ''
            },
            formRappelSmsPatient: {
                unjour: '',
                deuxjour: '',
                septjour: ''
            },
            formRappelSmsMedecin: {
                lejour: '',
                lelendemain: '',
                lasemaine: '',
                lasemainepro: ''
            }
        }
    },
    methods: {
        handleFormsanUpdateSubmit() {

        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        informationFormsan() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(INFORMATION_DU_PARTENAIRE_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    let resultat = res.data.resultat;

                    resultat.map((item) => {
                        if (item.length > 0) {
                            if (item[0].lib_para == "PFC_NOM_INFO") {
                                this.Formsan.nom = item[0].val_para
                            } else if (item[0].lib_para == "PFC_SG_INFO") {
                                this.Formsan.adresse = item[0].val_para
                            } else if (item[0].lib_para == "PFC_TEL_INFO") {
                                this.Formsan.phone = item[0].val_para
                            } else if (item[0].lib_para == "PFC_BP_INFO") {
                                this.Formsan.bp = item[0].val_para
                            }
                        }
                    })
                }
            });

        },
        modelRecuFacture() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(MODELE_FACTURE_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formModel.caisse = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formModel.nom_caisse = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formModel.pharmacie = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formModel.nom_pharmacie = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formModel.hospi = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formModel.nom_hospi = item[0].val_para
                    //         }
                    //     }
                    // });

                }
            });

        },
        affichageFacture() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(AFFICHAGE_FACTURE_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formFacture.titre = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formFacture.tva = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formFacture.dure = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formFacture.code_patient = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formFacture.mode_paiement = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formFacture.nom_agent = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formFacture.signature = item[0].val_para
                    //         }
                    //     }
                    // });
                }
            });

        },
        infoBasPage() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(INFORMATION_DU_BAS_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formInfoBasPage.slogan = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formInfoBasPage.affih_slogan = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formInfoBasPage.position_slogan = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formInfoBasPage.info_finance = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formInfoBasPage.affich_info_finance = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formInfoBasPage.position_info_finance = item[0].val_para
                    //         }
                    //     }
                    // });
                }
            });

        },
        activationCompteSms() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(RATTACHEMENT_SMS_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formCompteSms.rattacher = item[0].val_para
                    //         } 
                    //     }
                    // });
                }
            });

        },
        rapportPeriodique() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(RAPPORT_PERIODIQUE_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formRapportPeriod.sms_journalier = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formRapportPeriod.num_jour = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formRapportPeriod.sms_hebdo = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRapportPeriod.num_hebdo = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRapportPeriod.sms_mensuel = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRapportPeriod.num_mensuel = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRapportPeriod.sms_annuel = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRapportPeriod.num_annuel = item[0].val_para
                    //         }
                    //     }
                    // });
                }
            });

        },
        rappelSmsPatient() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(RAPPEL_SMS_PATIENT_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formRappelSmsPatient.unjour = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formRappelSmsPatient.deuxjour = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formRappelSmsPatient.septjour = item[0].val_para
                    //         }
                    //     }
                    // });
                }
            });

        },
        rappelSmsMedecin() {
            let data = new FormData();
            data.append('parnetaire_id', this.formsan_id);

            listeFetcherData(RAPPEL_SMS_MEDECIN_URL, data).then((res) => {
                if (res.data.status == "succes") {
                    // let resultat = res.data.resultat;
                    // resultat.map((item) => {
                    //     if (item.length > 0) {
                    //         if (item[0].lib_para == "PFC_NOM_INFO") {
                    //             this.formRappelSmsMedecin.lejour = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_SG_INFO") {
                    //             this.formRappelSmsMedecin.lelendemain = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_TEL_INFO") {
                    //             this.formRappelSmsMedecin.lasemaine = item[0].val_para
                    //         } else if (item[0].lib_para == "PFC_BP_INFO") {
                    //             this.formRappelSmsMedecin.lasemainepro = item[0].val_para
                    //         }
                    //     }
                    // });
                }
            });

        },
    },
    mounted() {
        this.formsan_id = this.$route.params.formsan_id;
        this.informationFormsan();
        this.modelRecuFacture();
        this.affichageFacture();
        this.infoBasPage();
        this.activationCompteSms();
        this.rapportPeriodique();
        this.rappelSmsPatient();
        this.rappelSmsMedecin();
    }
}
</script>

<style>
#section-parametres input,
#section-parametres select {
    height: 39px !important;
    box-shadow: unset !important;
    border: 1px solid rgba(0, 0, 0, .1) !important;
}

.relative small strong,
h2 {
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
}
</style>
